<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        :to="{name: 'login'}"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="mr-2"
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <!-- reset local storage -->
      <btn
        label="Reset Local Storage"
        class="login-pwa-controls push-left"
        :icon="icons.mdiDeleteForever"
        plain
        @click="resetStorage()"
      ></btn>

      <div class="login-pwa-controls push-right">
        <!-- update app -->
        <btn
          v-if="isProduction && isPWA"
          label="Update App"
          :icon="icons.mdiReload"
          plain
          @click="updateApp()"
        ></btn>

        <!-- note development mode -->
        <icon-value
          v-if="!isProduction"
          :icon="icons.mdiAccountCog"
          value="Dev Mode"
        ></icon-value>

        <!-- update version -->
        <span class="app-version">
          v{{ appVersion }}
        </span>
      </div>

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/login.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-5 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <div v-if="!isProduction">
                <alert>
                  Development Mode
                </alert>
                <div style="display: flex; justify-content: center; align-items: center">
                  <img
                    src="@/assets/images/logos/blackWhiteCat.png"
                    style="width: 100px; height: 100px;"
                  >
                  "X marks the spot!"
                </div>
              </div>
              <div v-if="isProduction && !isPWA">
                <alert color="error">
                  For proper operation, please install as a PWA!
                </alert>

                <alert
                  v-if="isChrome && !isIOS"
                  color="secondary"
                >
                  Chrome PWA installation instructions...
                  <ol class="mt-2">
                    <li>
                      Click Chrome's <v-icon style="height: 20px;">
                        {{ icons.mdiDotsVertical }}
                      </v-icon> menu icon at the top right
                    </li>
                    <li>Select <b style="color: var(--v-secondary-darken2)">Install SWC...</b> from the menu</li>
                    <li>Click the <b style="color: var(--v-secondary-darken2)">Install</b> button to install and start</li>
                  </ol>
                  <ul class="mt-2 mb-0">
                    <li>Run the <b style="color: var(--v-secondary-darken2)">SWC</b> app from Chrome's apps icon</li>
                    <li>You can also pin the app to Window's taskbar or add to Mac's dock</li>
                  </ul>
                </alert>

                <alert
                  v-if="!isChrome && isIOS"
                  color="secondary"
                >
                  iPad PWA installation instructions...
                  <ol class="mt-2">
                    <li>
                      Tap the <v-icon style="height: 20px; transform: rotate(270deg)">
                        {{ icons.mdiLogout }}
                      </v-icon> icon in the top address bar
                    </li>
                    <li>Select <b style="color: var(--v-secondary-darken2)">Add to Home Screen</b> from the menu</li>
                    <li>Select <b style="color: var(--v-secondary-darken2)">Add</b> to install the PWA</li>
                  </ol>
                  <ul class="mt-2 mb-0">
                    <li>The <b style="color: var(--v-secondary-darken2)">SWC</b> app installs to the home screen</li>
                    <li>You can also drag and drop the app to the dock</li>
                  </ul>
                </alert>
              </div>

              <v-card flat>
                <v-card-text>
                  <p
                    class="text-2xl font-weight-semibold text--primary mb-2"
                  >
                    {{ isProduction ? appTitle : 'Treasure' }}
                    <img
                      v-if="!isProduction"
                      src="@/assets/images/logos/bandaidsX.jpg"
                      style="width: 20px; height: 20px;"
                    >
                  </p>
                  <p class="mb-2">
                    Please sign-in to your account
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="login"
                    v-model="formValid"
                    @submit.prevent="loginClick()"
                  >
                    <text-field
                      v-model="email"
                      label="Email"
                      rules="email"
                      email
                      required
                    ></text-field>

                    <text-field
                      v-model="password"
                      label="Password"
                      rules="minLength(8)"
                      password
                      required
                    ></text-field>

                    <div
                      class="d-flex align-center justify-space-between flex-wrap"
                    >
                      <v-checkbox
                        v-model="remember"
                        hide-details
                        label="Remember Me"
                        class="mt-0 pt-0 mb-5"
                      >
                      </v-checkbox>

                      <!-- forget password link -->
                      <btn
                        label="Forgot Password?"
                        rem-offline-disable
                        plain
                        class="mb-5"
                        @click="forgotPasswordClick()"
                      ></btn>
                    </div>

                    <btn
                      :disabled="!formValid"
                      label="Login"
                      :icon="icons.mdiLogin"
                      :loading="loading"
                      type="submit"
                      block
                    ></btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import themeConfig from '@themeConfig'
import {
  mdiLogin, mdiDeleteForever, mdiReload, mdiAccountCog, mdiCellphoneArrowDown, mdiDotsVertical, mdiLogout,
} from '@mdi/js'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ResetStorage from '@/mixins/ResetStorage'

export default {
  mixins: [ResetStorage],
  data() {
    return {
      onLine: navigator.onLine,
      formValid: false,
      email: this.remember ? this.$store.state.auth.user.email : '',
      password: '',
      appName: themeConfig.app.name,
      appTitle: themeConfig.app.title,
      appLogo: themeConfig.app.logo,
      appVersion: themeConfig.app.version,
      icons: {
        mdiLogin, mdiDeleteForever, mdiReload, mdiAccountCog, mdiCellphoneArrowDown, mdiDotsVertical, mdiLogout,
      },
    }
  },
  computed: {
    ...mapFields(['online']),
    ...mapFields('auth', {
      remember: 'user.remember',
      user: 'user',
      loading: 'loading',
      darkMode: 'darkMode',
    }),
    forgotPasswordUrl() {
      return process.env.VUE_APP_FORGOT_PASSWORD_URL
    },
    isProduction() {
      return process.env.NODE_ENV === 'production'
    },
    isPWA() {
      return window.matchMedia('(display-mode: standalone)').matches
    },
    isChrome() {
      return navigator.vendor.indexOf('Google') > -1
    },
    isIOS() {
      return navigator.platform === 'iPad' || navigator.platform === 'iPhone'
    },
  },
  watch: {
    onLine(v) {
      console.log(v)
    },
  },
  mounted() {
    window.addEventListener('online', event => {
      console.log('went online')
    })
    window.addEventListener('offline', event => {
      console.log('went offline')
    })
    window.addEventListener('offline', this.updateOnlineStatus)
    this.$store.dispatch('auth/setLoading', false)
    this.$store.dispatch('auth/logout').then(() => {
      setTimeout(() => {
        if (this.$route?.query?.notify) {
          this.$store.dispatch('notify', {
            value: this.$route.query?.notify,
            color: this.$route.query?.color ? this.$route.query.color : 'info',
            timeout: this.$route.query?.timeout ? this.$route.query.timeout : 4000,
          })
        }
      }, 100)
    })
  },
  methods: {
    ...mapActions('auth', ['login']),
    updateOnlineStatus(e) {
      const {
        type,
      } = e
      this.onLine = type === 'online'
    },
    loginClick() {
      if (this.$refs.login.validate()) {
        this.login({
          email: this.email.trim().toLowerCase(),
          password: this.password.trim(),
          remember: this.remember,
        }).then(response => {
          if (response.success) {
            this.$router.push(response.redirect).catch(e => { console.log(e) })
          }
        })
      }
    },
    forgotPasswordClick() {
      /*
      this.user.email = this.email
      this.$router.push({ name: 'forgot-password' })
      */

      window.location.href = this.forgotPasswordUrl
    },
    updateApp() {
      this.$store.dispatch('updateApp').then(() => {
        window.location.reload()
        this.$router.replace({ query: {} })
      })
    },
  },

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
.auth-wrapper.auth-v2 .auth-inner .auth-bg .v-card {
  max-width: none !important;
}
.login-pwa-controls {
  position: absolute;
  bottom: 1.83rem;
  line-height: 45px;
  z-index: 1;
  &.push-left {
    left: 2.5rem;
  }
  &.push-right {
    right: 2.5rem;
    .app-version {
      font-size: 15px;
    }
  }
  .icon-value {
    display: inline-block;
    text-transform: uppercase;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
  }
}
</style>
